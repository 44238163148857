@import "../../../../assets/scss/settings";

.question-card {
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
  //.mat-checkbox-label {
  //  max-width: 250px;
  //  overflow-x: hidden;
  //  text-overflow: ellipsis;
  //}
}

.question-card__header__actions__tooltip {
  background: $grey-3;
  color: $white;
}
