.attachment-chip {
  .mat-chip-list-wrapper {
    margin: 0;

    .mat-standard-chip.mat-chip-with-trailing-icon {
      cursor: pointer;
      padding: 4px 6px 4px 10px;
      margin: 0;
    }
  }
}
