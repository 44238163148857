@import "../../../../assets/scss/settings";

.user-bar__mat-menu {
  .mat-menu-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    & > div {
      background-color: $background-grey;
    }
    & > div:nth-child(1) {
      padding: $small-margin $small-margin $smallest-margin;
    }
    & > div:nth-child(2) {
      color: $grey-3;
      padding: $smallest-margin $small-margin $small-margin;
    }
    button {
      width: 100%;
      max-width: unset;
    }
  }
}
