// a. Base
// b. Global

// a. Base
// - - - - - - - - - - - - - - - - - - - - - - - - -
// This is the default html and body font-size for the base rem value.
$rem-base: 16;

// The default font-size is set to 100% of the browser style sheet (usually 16px)
$base-font-size: 16px;
// The $base-font-size is 100% while $base-line-height is 150%
$base-line-height: 100%;

// b. Global
// - - - - - - - - - - - - - - - - - - - - - - - - -

// We use these to define default font weights
$font-weight-normal: 400;
$font-weight-bold: 600;

// Font families
$roboto: 'Roboto';

// We use these to control various global styles
$body-font-color: #000000;
$body-font-family: $roboto, sans-serif;
$body-font-weight: $font-weight-normal;
$body-font-style: normal;

// We use this to control font-smoothing
$font-smoothing: antialiased;

// Colours
$primary-color: #0079C1;
$accent-color: #FFE066;
$orange: #ffd185;
$error-color: #DC493A;
$warning-yellow: #ffc82c;

$background-grey: #f2f2f2;
$grey-1: #cacfd4;
$grey-2: #8492A6;
$grey-3: #808080;
$grey-4: #222222;
$snow: #F3F5F6;

// Semantically significant colours
$inspection-colour: #9DD9D2;
$processing-colour: #59C9A5;
$awaiting-revision-colour: #5cc6ff;
$under-revision-colour: #359AB1;
$awaiting-supervision-colour: #9d90f7;
$under-supervision-colour: #533de3;
$awaiting-correction-colour: #ff6d52;
$under-correction-colour: #ff6d52;
$completed-colour: $primary-color;

$white: #FFFFFF;
$black: #000000;

$shadow-color: rgba(0, 0, 0, 0.33);

$primary: $primary-color;

$primary-palette: (
  50: #e0eff8,
  100: #b3d7ec,
  200: #80bce0,
  300: #4da1d4,
  400: #268dca,
  500: #0079c1,
  600: #0071bb,
  700: #0066b3,
  800: #005cab,
  900: #00499e,
  A100: #c9deff,
  A200: #96bfff,
  A400: #63a0ff,
  A700: #4990ff,
  contrast: (
    50: $body-font-color,
    100: $body-font-color,
    200: $body-font-color,
    300: $body-font-color,
    400: $body-font-color,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
    A100: $body-font-color,
    A200: $white,
    A400: $white,
    A700: $white,
  )
);

$accent-palette: (
  50 : #fffbed,
  100 : #fff6d1,
  200 : #fff0b3,
  300 : #ffe994,
  400 : #ffe57d,
  500 : #ffe066,
  600 : #ffdc5e,
  700 : #ffd853,
  800 : #ffd349,
  900 : #ffcb38,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #fff7e1,
  A700 : #fff0c8,
  contrast: (
    50 : $body-font-color,
    100 : $body-font-color,
    200 : $body-font-color,
    300 : $body-font-color,
    400 : $body-font-color,
    500 : $body-font-color,
    600 : $body-font-color,
    700 : $body-font-color,
    800 : $body-font-color,
    900 : $body-font-color,
    A100 : $body-font-color,
    A200 : $body-font-color,
    A400 : $body-font-color,
    A700 : $body-font-color,
  )
);

// Global font sizes
$font-size-label: 87.5%;        // 14px
$font-size-p: 100%;             // 16px
$font-size-h4: 112.5%;          // 18px
$font-size-h2: 131.25%;         // 21px


// Margins
$smallest-margin: 0.25rem;    // 4px
$smaller-margin: 0.75rem;     // 12px
$small-margin: 1rem;          // 16px
$medium-margin: 2rem;         // 32px
$large-margin: 2.5rem;        // 40px
$page-margin: 0 $medium-margin $small-margin $medium-margin;

// Radius values
$container-radius: 4px;
$button-radius: 2px;

$search-filter-height: 32px;

$material-components-height: 36px;

$max-width: 1280px;

$sebastian-border: 1px solid $grey-1;


/* MIXINS */

@mixin rounded($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin shadowed() {
  -webkit-box-shadow: 0 2px 4px 0px $shadow-color;
  -moz-box-shadow: 0 2px 4px 0px $shadow-color;
  box-shadow: 0 2px 4px 0px $shadow-color;
}

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex:  $values;
  -webkit-flex:  $values;
  -ms-flex:  $values;
  flex:  $values;
}

@mixin no-select() {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

@mixin sebastian-header() {
  &__header {
    @include flexbox();
    flex-direction: row;
    justify-content: space-between;
    &__button {
      margin-top: -0.6rem;
    }
  }
}

@mixin sebastian-file-table() {
  &__table {
    max-width: $max-width;
    margin-top: $large-margin;
    &__header, &__body__row {
      @include flexbox();
      align-items: center;
      & > div {
        margin-left: $smaller-margin;
      }
    }
    &__header {
      margin-bottom: $small-margin;
      font-weight: $font-weight-bold;
    }
    &__body {
      &__row {
        padding: $smaller-margin 0;
        background-color: $white;
        margin: $smaller-margin 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
