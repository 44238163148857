@import "./settings.scss";
@import "./deep";

/*
  Index:
  - General rules
  - Typography
*/

// General rules
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  line-height: $base-line-height;
  overflow-y: hidden;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $body-font-family;
}

*:focus {
  outline: 0;
}

body {
  font-family: $body-font-family;
  font-weight: $font-weight-normal;
  color: $body-font-color;
  height: 100%;
  font-size: $base-font-size;
}

[hidden] {
  visibility: hidden;
}

p {
  white-space: pre-wrap;
}

svg|title {
  pointer-events: none;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar, ::-webkit-scrollbar-button, ::-webkit-scrollbar-track, ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: $primary-color;
  border-radius: 0.3rem;
}

.spinner {
  display: inline-block;
  width: 40px;
  height: 40px;
  &:after {
    content: " ";
    display: block;
    width: 28.75px;
    height: 28.75px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid $primary-color;
    border-color: $primary-color transparent $primary-color transparent;
    animation: lds-dual-ring 1.2s ease-in-out infinite;
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

a {
  color: $body-font-color;
  text-decoration: none;
  &.active {
    border-bottom: 4px solid $primary-color;
    padding-bottom: $smallest-margin;
    //border-bottom: 4px solid transparent;
    &--bold {
      font-weight: $font-weight-bold;
    }
  }
}

*.active {
  a {
    border-bottom: 4px solid $primary-color;
  }
  &--bold {
    a {
      font-weight: $font-weight-bold;
    }
  }
}

.sebastian-status {
  @include no-select();
  width: 100%;
  max-width: 150px;
  height: 35px;
  display: grid;
  place-items: center;
  font-weight: bold;
  background: grey; // TODO colore giusto
  color: $white;

  transition: background-color 200ms ease-out;
  &--inspection {
    background: $inspection-colour;
    color: $black;

    &.sebastian-status--not-downloaded {
      background: grey;
      color: $grey-4;
    }
  }
  &--processing {
    background: $processing-colour;
    color: $grey-4;
  }
  &--awaiting_revision {
    background: $awaiting-revision-colour;
  }
  &--under_revision {
    background: $under-revision-colour;
  }
  &--awaiting_supervision {
    background: $awaiting-supervision-colour;
  }
  &--under_supervision {
    background: $under-supervision-colour;
  }
  &--awaiting_correction {
    background: $awaiting-correction-colour;
  }
  &--under_correction {
    background: $under-correction-colour;
  }
  &--completed {
    background: $completed-colour;
  }
}

.sebastian-button {
  font-size: $font-size-p;
  font-weight: $font-weight-bold;
  min-width: 170px;
  &--primary {
    background-color: $primary-color;
    color: $white;
  }
  &--secondary {
  }
  &--warning {
    background-color: $error-color;
    color: $white;
  }
}

button:not(.sebastian-button) {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mat-button.icon-button {
  padding: 0 7px;
}

.mat-form-field-flex {
  height: $material-components-height;
  align-items: center !important;
}

.sebastian-select {
  .mat-form-field-flex {
    padding-left: $smaller-margin;
    border-radius: $container-radius;
  }
  .mat-form-field-infix {
    border-top: none;
    //font-weight: $font-weight-bold;
    font-size: $font-size-label;
  }
  .mat-form-field-underline {
    display: none;
  }
  &--border {
    .mat-form-field-flex {
      border: 1px solid $grey-2;
    }
  }
}

mat-option.mat-selected .mat-option-text {
  color: $black;
}

.sebastian-input {
  .mat-form-field-wrapper {
    padding: 0;
    .mat-form-field-infix {
      border-top: none;
      //font-weight: $font-weight-bold;
      font-size: $font-size-label;
      input {
        padding-left: $smaller-margin;
        caret-color: $black;
      }
    }
    .mat-form-field-underline {
      display: none;
    }
    .mat-form-field-flex {
      align-items: center;
      border: 1px solid $grey-2;
      border-radius: $container-radius;
      background-color: white;
      padding: $smallest-margin $smallest-margin $smallest-margin 0;
      .mat-form-field-prefix {
        display: flex;
        align-items: center;
        margin-left: $smallest-margin;
      }
    }
    .mat-form-field-label {
      padding-left: $smaller-margin;
    }
  }
  input[type=number] {
    text-align: right;
    padding-right: $smallest-margin;
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
}

.sebastian-datepicker {
  mat-form-field {
    cursor: pointer;
  }
}

.sebastian-radio-group {
  .mat-radio-group {
    height: $material-components-height;
    display: inline-flex;
    align-items: center;
  }
  .mat-radio-label-content {
    padding-left: 0;
    padding-right: $small-margin;
    width: 120px;
  }
  .mat-radio-container {
    display: none;
  }
  .mat-radio-checked {
    .mat-radio-label-content {
      color: $primary-color;
      font-weight: $font-weight-bold;
    }
  }
}

.sebastian-back {
  position: absolute;
  top: 60px;
  left: 40px;
  mat-icon {
    cursor: pointer;
    font-size: 32px;
  }
}

.mat-tooltip {
  font-family: $body-font-family;
  font-size: $font-size-label;
  font-weight: $font-weight-bold;
  opacity: .8;
}

.mat-checkbox-frame {
  border-width: 1px !important;
}

textarea {
  width: calc(100% - 2px - 2 * #{$smaller-margin});
  border: 1px solid $grey-2;
  border-radius: $container-radius;
  padding: 0.5rem $smaller-margin;
  font-size: $font-size-label;
  resize: vertical;
  min-height: 36px;
  max-width: 100%;
  caret-color: $body-font-color;
  box-sizing: content-box;
}

// Typography

.material-icons {
  font-family: 'Material Icons' !important;
}

h2 {
  color: $body-font-color;
  font-family: $body-font-family;
  font-size: $font-size-h2;
  font-weight: $font-weight-normal;
  margin: 0;
}

h4 {
  color: $body-font-color;
  font-family: $body-font-family;
  font-size: $font-size-h4;
  font-weight: $font-weight-normal;
  margin: 0;
}

p {
  color: $body-font-color;
  font-family: $body-font-family;
  font-size: $font-size-p;
  font-weight: $font-weight-normal;
  line-height: 1.2;
  margin: 0;
}

label {
  color: $body-font-color;
  font-family: $body-font-family;
  font-size: $font-size-label;
  font-weight: $font-weight-normal;
  line-height: 1;
  margin: 0;
}

.accent-color {
  color: $accent-color;
}

.bold {
  font-weight: $font-weight-bold;
}

// Snack bar
snack-bar-container {
  simple-snack-bar {
    font-family: $body-font-family;

    .mat-simple-snackbar-action {
      button {
        color: $white;
      }
    }
  }

  &.error {
    background: $error-color;
  }
}

// Slide toggle
mat-slide-toggle {

}

.hidden {
  display: none !important;
}

.inputfile {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

// Dialogs
mat-dialog-container {
  mat-dialog-content {
    p {
      line-height: 1.4;
    }
  }
  mat-dialog-actions {
    @include flexbox();
    justify-content: center;
    margin-top: $small-margin;
    font-family: $body-font-family;

    .mat-button span {
      font-weight: $font-weight-bold;
    }
  }
}

.error {
  .mat-form-field-flex {
    border-color: red !important;

  }
}

.mat-input-element:disabled {
  color: unset;
}

.mat-bottom-sheet-container-medium {
  min-width: 512px !important;
}
