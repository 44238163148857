
.bollocks {
  filter: blur(0.5px) hue-rotate(0deg);
  animation: im-on-meth 4s linear infinite;

  #app-content {
    animation: slightly-unstable 4s linear infinite;
  }

  * {
    text-transform: uppercase;
  }

  p {
    transform: scale(1, -1);
  }

  .notes-sheet__body {
    background: url("/assets/images/ayylmao.jpg") !important;
    background-size: 100% 100%;
  }

  mat-dialog-container {
    border-radius: 50%;
    display: grid;
    height: 650px;
    place-items: center;
    width: 650px;
  }

  .sebastian-status {
    background: url("/assets/images/hundred-dollar-bill.jpg") !important;
    background-size: 100% 100% !important;
    color: $white !important;
  }

  .question-card, .assignment__card {
    animation: im-f-ing-hammered-mate 4s linear infinite;
  }

  .question-card:hover {
    background-image: url("/assets/images/hundred-dollar-bill.jpg") !important;
    background-size: 50px 21px !important;
  }

  .user-bar__logo {
    &:not(.ayylmao) {
      display: none !important;
    }
    &.ayylmao {
      display: block !important;
    }
  }

  .user-bar, button {
    animation: blazeit 1.5s linear infinite;
  }

  .mat-bottom-sheet-container {
    transform: rotate(6deg) !important
  }

  .side-menu__link:hover {
    display: none;
  }

  mat-icon {
    animation: dey-c-me-rollin 5s linear infinite;
  }

  @keyframes blazeit {
    0% {
      background-color: orangered;
    }
    20% {
      background-color: greenyellow;
    }
    45% {
      background-color: deeppink;
    }
    78% {
      background-color: darkorange;
    }
  }

  @keyframes im-f-ing-hammered-mate {
    50% {
      transform: scale(1.1);
    }
  }

  @keyframes im-on-meth {
    30% {
      filter: blur(0.5px) hue-rotate(90deg);
    }
    42%{
      filter: blur(0.65px) hue-rotate(90deg);
    }
    65% {
      filter: blur(0.75px) hue-rotate(180deg);
    }
    89% {
      filter: blur(1px) hue-rotate(270deg);
    }
    99% {
      filter: blur(3px) invert(100%);
      background-image: url("/assets/images/ayylmao.jpg") !important;
    }
  }

  @keyframes dey-c-me-rollin {
    0% {
      transform: rotate(0deg);
    }
    46% {
      transform: rotate(185deg);
    }
    48% {
      transform: rotate(170deg);
    }
    50% {
      transform: rotate(190deg);
    }
    52% {
      transform: rotate(173deg);
    }
    54% {
      transform: rotate(200deg);
    }
    100% {
      transform: rotate(348deg);
    }
  }
  
  @keyframes slightly-unstable {
    0% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(2deg);
    }
    60% {
      transform: rotate(-2deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}
